import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { App } from '@capacitor/app'

import { Page, View, Text, SEO, Button } from 'lib'
import { useUserData } from './dashboard/_utils'
import theme from '_appSetup/Theme'

import '_appSetup/Global.css'

const SupportPage = (props) => {
  const styles = useStyles(props)
  const [versionNumber, setVersionNumber] = useState(null)

  const { UserData } = useUserData()

  useEffect(() => {
    try {
      App.getInfo().then(info => {
        setVersionNumber(info.version)
      })
    } catch {}
  }, [])

  const getEmailTemplate = () => {
    return `mailto:support@stablegains.com?subject=Feedback&body=
    
    
    ------  
    Diagnostic information:
    Account email: ${UserData.email}
    Version number: ${versionNumber || 'N/A'}
    ------`
  }

  return (
    <Page>
      <SEO title='Support'/>
      <View style={styles.leftWrapper}>

        <Text variant='h5' gutterBottom style={styles.header}>Get support</Text>
        <Text gutterBottom>See answers to the most common questions and contact our team in the support center:</Text>
        <Button href='https://stablegains.zendesk.com/hc/en-us' target='_blank' color='primary' variant='contained' style={styles.button}>
          Open support center
        </Button>

        {versionNumber && (
          <>
            <View style={styles.separator}/>
            <Text variant='subtitle2' style={styles.opaque} text={`App version number: ${versionNumber}`}/>
          </>
        )}


      </View>
    </Page>
  )
}


const useStyles = makeStyles({
  leftWrapper: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  button: {
    marginLeft: 0,
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0.5),
    borderTop: '1px solid #00000016',
    paddingTop: theme.spacing(4),
  },
  opaque: {
    opacity: 0.5,
  },
  disclaimer: {
    marginTop: theme.spacing(2),
  },
})

export default SupportPage
